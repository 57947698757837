import React, {useContext, useState} from 'react';
import s from './styles.module.css'
import {
    Avatar,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Tooltip,
    Typography,
    useMediaQuery
} from "@mui/material";
import logo from '../../assets/logo'
import {UserContext} from "../../page/lkContainer";
import {formatNumberWithSpaces} from "../../utils/formatNumber";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import {ReactComponent as Icon1} from "../../assets/navigate/icon1.svg";
import {ReactComponent as Icon2} from "../../assets/navigate/icon2.svg";
import {ReactComponent as Icon3} from "../../assets/navigate/icon3.svg";
import {ReactComponent as Icon4} from "../../assets/navigate/icon4.svg";
import {ReactComponent as Icon5} from "../../assets/navigate/icon5.svg";
import {ReactComponent as Icon6} from "../../assets/navigate/icon6.svg";
import classNames from "classnames";
import {NavLink, useLocation} from "react-router-dom";
import {AuthContext} from "../../App";
import {toast} from "react-toastify";
import ReactFlagsSelect from "react-flags-select";
import Settings from "./settings";
import NavigateItems from "./navigateItems";
import HeaderNavigate from "./headerNavigate";

export const inDev = () => {
    toast.warning('In developing')
}

export const navigateItem = [
    {title: 'Profile', icon: <Icon1/>, link: ''},
    // {title: 'Token shares', icon: <Icon2/>, link: 'token-shares'},
    // {title: 'Structure', icon: <Icon3/>, link: 'structure'},
    // {title: 'Partners', icon: <Icon4/>, link: 'partners'},
    // {title: 'History', icon: <Icon5/>, link: 'history'},
    // {title: 'News', icon: <Icon6/>, link: 'news'},
    {title: 'Lenders', icon: <Icon2/>, link: 'lenders'},
    {title: 'Borrowers', icon: <Icon3/>, link: 'borrowers'},
    {title: 'Affiliates', icon: <Icon4/>, link: 'affiliates'},
    {title: 'Insurance', icon: <Icon5/>, link: 'insurance'},
    {title: 'Credit Pool', icon: <Icon6/>, link: 'credit-pool'},
    {title: 'Credit Certificates', icon: <Icon6/>, link: 'credit-certificates'},
]

const Navigate = ({title}) => {
    const matches768 = useMediaQuery('(max-width:768px)');

    if (matches768) {
        return <HeaderNavigate title={title}/>
    } else {
        return <div className={s.navigate}>
            <img alt={'logo'} src={logo}
                 style={{maxWidth: '130px', width: '130px', height: '70px', maxHeight: '70px', borderRadius: '0px'}}/>

            <Settings/>


            <NavigateItems/>
        </div>
    }


};

export default Navigate;
