// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_title__c4MZv {
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
    font-family: Inter, sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 26.63px;
    text-align: left;
    color: #086A8C;
}`, "",{"version":3,"sources":["webpack://./src/commom/titleCabinet/styles.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,uBAAkB;IAAlB,kBAAkB;IAClB,8BAA8B;IAC9B,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".title {\n    white-space: nowrap;\n    width: fit-content;\n    font-family: Inter, sans-serif;\n    font-size: 22px;\n    font-weight: 500;\n    line-height: 26.63px;\n    text-align: left;\n    color: #086A8C;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `styles_title__c4MZv`
};
export default ___CSS_LOADER_EXPORT___;
