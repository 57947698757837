import React from 'react';
import s from './styles.module.css'
import classNames from "classnames";
import TableAffiliates from "./table";
import {NavLink} from "react-router-dom";

const Affiliates = () => {
    return (
        <div className={s.main}>
            <div className={s.text_box}>
                <p className={classNames(s.text, s.text_title)} style={{fontWeight: 900, fontSize: '24px'}}>Access fast
                    Grow your reach and earn with Webtransfer’s Affiliate Program.</p>
                <p className={s.text}>Webtransfer’s affiliate program is designed for members to not only earn through
                    new user referrals but also to fortify their standing within the credit network. Partners can
                    establish their own <NavLink className={s.link}
                                                 to={'/lk/credit-certificates'} target={'_blank'}>credit
                        unions</NavLink> to collaboratively finance loans
                    and manage risks:</p>
            </div>
            <div className={s.text_box}>
                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Rewards: </b>
       Earn bonuses for each new lender and borrower you refer, along with a percentage from the transactions within your network.</span>
                </li>
                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Risk Management: </b>
          Contribute to a secure credit environment by diversifying risk across union members.</span>
                </li>
                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Community Growth: </b>
                       Drive the platform’s growth and development by attracting fresh financial resources and community members.</span>
                </li>
            </div>

            <div className={s.text_box}>
                <p className={s.text}>Become a part of the Webtransfer affiliate network and utilize the opportunities
                    presented by a decentralized financial ecosystem to reach your objectives.</p>
            </div>

            <br/>
            <div className={s.text_box}>
                <p className={classNames(s.text, s.text_title)}>Partner Incentives and Training:</p>

                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Registration Bonus: </b>
      As a new partner, you will receive a $50 credit bonus upon registering and for every new member you bring into Webtransfer.</span>
                </li>

                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Loan Issuance: </b>
     You can utilize these credit bonuses to issue loans directly from your dashboard.*</span>
                </li>

                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Bonus Purpose: </b>
     Credit bonuses are intended to serve as a practical learning tool for the lending process, ensuring your understanding of the system before working with your own funds.</span>
                </li>

                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Insured Loans: </b>
Bonus loans are available with an insurance option, creating a safer environment for new members who are still familiarizing themselves with the platform.</span>
                </li>

                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Bonus Cancellation: </b>
These credit bonuses are automatically canceled after the loan has been returned.
                    </span>
                </li>
                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Real Profits: </b>
Any profit earned from these credit bonuses is real and can be withdrawn at the end of the introductory period.
                    </span>
                </li>

            </div>

            <br/>
            <div className={s.text_box}>
                <p className={classNames(s.text, s.text_title)}>Webtransfer: Expanding Opportunities Together</p>

                <p className={classNames(s.text)}>Webtransfer is a pioneer in the field of social lending and aims to
                    innovate in the area of decentralized finance. While we are not an MLM platform, in response to the
                    requests of our community members and their active recommendations, we have developed a network
                    referral reward system. This system expands and strengthens our lending network through profits
                    generated from referred partners. Additionally, active leaders receive additional benefits that
                    contribute to the further development and strengthening of our community.</p>
            </div>
            <br/>
            <div className={s.text_box}>
                <p className={classNames(s.text, s.text_title)}>Partnership Program and Rewards</p>

                <p className={classNames(s.text)}>Based on suggestions from our community members, we have developed a
                    reward structure that supports and encourages both active participation in lending and the
                    opportunity for passive income through <a className={s.link}
                                                              href="/" target={'_blank'}>credit certificates
                        100.</a> This structure not only strengthens
                    economic ties within the network but also expands financial opportunities on an international
                    level.</p>
            </div>
            <br/>
            <div className={s.text_box}>
                <p className={classNames(s.text, s.text_title)}>Earning from Lending</p>

                <p className={classNames(s.text)}>
                    Participants earn a percentage of the interest from loans issued by their referred partners. The
                    more their partners earn, the greater their own earnings.
                </p>
            </div>
            <br/>
            <div className={s.text_box}>
                <p className={classNames(s.text, s.text_title)}>Income Structure</p>

                <p className={classNames(s.text)}>
                    Your income depends on your personal loans issued and the total volume of active loans managed by
                    your team.
                </p>
            </div>
            <br/>

            <div className={s.text_box}>
                <p className={classNames(s.text, s.text_title)}>Levels and Rewards</p>

                <p className={classNames(s.text)}>
                    <b>
                        Below is the reward table outlining the profit percentages from loans issued by your junior
                        partners:
                    </b>
                </p>

                <TableAffiliates/>
            </div>

            <br/>

            <div className={s.text_box}>
                <p className={classNames(s.text, s.text_title)}>Total: 41% of Partner Income</p>

                <p className={classNames(s.text)}>
                    The percentage of partner income is credited monthly at the beginning of each month, from the 1st to
                    the 5th.
                </p>
            </div>

            <div className={s.text_box}>
                <p className={classNames(s.text, s.text_title)}>Extra 5% Commission for Top Teams</p>

                <p className={classNames(s.text)}>
                    In addition, 5% of the commissions accumulated in the insurance pool are distributed among partners
                    whose team revenue has exceeded $1,000,000.
                </p>
                <p className={classNames(s.text)}>
                    This structure ensures that every participant can benefit directly from the growth and success of
                    their network, enhancing the overall robustness and reach of the Webtransfer community.
                </p>
                <p className={classNames(s.text)}>
                    *Please note that loans with bonus funds will be available after the platform launch. At the moment,
                    bonuses are being accumulated for invitations. Create teams and expand your networks to maximize
                    your opportunities in the future.
                </p>
                <p className={classNames(s.text)}>
                    We strongly advise against creating fake accounts. If such actions are detected, your account may be
                    blocked, and all accumulated bonuses will be forfeited.
                </p>
            </div>

        </div>
    );
};

export default Affiliates;