// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_header__Z9Xqu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 16px;
}

.styles_title__aV\\+3Q {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 24px;
    color: rgba(84, 84, 91, 1)
}
`, "",{"version":3,"sources":["webpack://./src/commom/modal/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,gCAAgC;IAChC,gBAAgB;IAChB,eAAe;IACf;AACJ","sourcesContent":[".header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    gap: 16px;\n    margin-bottom: 16px;\n}\n\n.title {\n    font-family: 'Inter', sans-serif;\n    font-weight: 600;\n    font-size: 24px;\n    color: rgba(84, 84, 91, 1)\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `styles_header__Z9Xqu`,
	"title": `styles_title__aV+3Q`
};
export default ___CSS_LOADER_EXPORT___;
