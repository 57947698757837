import React, {useState} from 'react';
import s from './styles.module.css'
import MenuIcon from '@mui/icons-material/Menu';
import {Box, SwipeableDrawer} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import logo from "../../../assets/logo";
import Settings from "../settings";
import NavigateItems from "../navigateItems";

const HeaderNavigate = ({title}) => {
    const iOS =
        typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
    const [openMenu, setOpenMenu] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setOpenMenu(open);
    };
    return (
        <>
            <div className={s.header}>
                <p className={s.title}>{title}</p>
                <div className={s.burger} onClick={toggleDrawer(!openMenu)}>
                    {openMenu ? <CloseIcon/> : <MenuIcon/>}
                </div>
            </div>

            <React.Fragment>
                <SwipeableDrawer
                    sx={{
                        top: '60px',


                        '& .MuiBackdrop-root': {
                            marginTop: '60px'
                        },
                        '& .MuiPaper-root': {
                            marginTop: '60px',
                            boxShadow: 'none',
                            width: '80%',
                            height: 'calc(100% - 52px)'
                        },

                    }}
                    disableBackdropTransition={!iOS}
                    disableDiscovery={iOS}
                    anchor={'right'}
                    open={openMenu}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    <Box

                        role="presentation"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                    >
                        <div className={s.navigate}>
                            <div className={s.logo}>
                                <img alt={'logo'} src={logo}
                                     style={{
                                         maxWidth: '130px',
                                         width: '130px',
                                         height: '70px',
                                         maxHeight: '70px',
                                         borderRadius: '0px'
                                     }}/>
                            </div>

                            <Settings/>

                            <NavigateItems/>
                        </div>
                    </Box>
                </SwipeableDrawer>
            </React.Fragment>
        </>

    );
};

export default HeaderNavigate;