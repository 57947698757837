import React from 'react';
import s from './styles.module.css'

const Input = ({title, value = '', handleChange, placeholder, disabled = false, style}) => {
    return (
        <div className={s.input_box}>
            {title && <p className={s.title}>{title}</p>}
            <input style={style} className={s.input} disabled={disabled} placeholder={placeholder} type="text"
                   value={value}
                   onChange={handleChange}/>
        </div>
    );
};

export default Input;