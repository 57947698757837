import React from 'react';
import {Paper, Table, TableBody, TableContainer, TableHead, TableRow} from "@mui/material";
import TableCell from "@mui/material/TableCell";

function createData(level, income, active, team) {
    return {level, income, active, team};
}

const rows = [
    createData(1, '20%', '$100 – $1,000', 'up to $10,000'),
    createData(2, '10%', '$1001 – $5,000', '$10,001 – $50,000'),
    createData(3, '5%', '$5,001 – $10,000', '$50,001 – $150,000'),
    createData(4, '3%', '$10,001 – $30,000', '$150,001 – $300,000'),
    createData(5, '3%', 'from $50,000 and more', '$500,000'),
];


const TableAffiliates = () => {
    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Level</TableCell>
                        <TableCell align="right">Partner Income %</TableCell>
                        <TableCell align="right">Personal active loans</TableCell>
                        <TableCell align="right">Team active loans</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.level}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" scope="row">
                                {row.level}
                            </TableCell>
                            <TableCell align="right">{row.income}</TableCell>
                            <TableCell align="right">{row.active}</TableCell>
                            <TableCell align="right">{row.team}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableAffiliates;