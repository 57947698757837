import React from 'react';
import s from './styles.module.css'
import classNames from "classnames";
import {NavLink} from "react-router-dom";

const Borrowers = () => {
    return (
        <div className={s.main}>
            <div className={s.text_box}>
                <p className={classNames(s.text, s.text_title)} style={{fontWeight: 900, fontSize: '24px'}}>Access fast
                    and reliable loans within a global
                    decentralized network.</p>
                <p className={s.text}>Borrowers in the Webtransfer system can obtain loans on competitive terms thanks
                    to an innovative blockchain-based credit scoring system:</p>
            </div>
            <div className={s.text_box}>
                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Quick Approval: </b>
       The review and approval process is minimized due to an automated scoring system.</span>
                </li>
                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Flexible Terms: </b>
          Loan conditions are adapted to your current financial state and social rating.</span>
                </li>
                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Guarantor Option: </b>
                        Increase your chances of obtaining a loan by attracting guarantors from other network participants.</span>
                </li>
            </div>
            <div className={s.text_box}>
                <p className={s.text}>Utilizing the Webtransfer platform, borrowers can not only meet their short-term
                    financial needs but also improve their credit history within the system, which opens the door to
                    more favorable terms in the future.</p>
            </div>

            <div className={s.text_box}>
                <p className={s.text}>Webtransfer offers two main types of loans: secured and unsecured, with the option
                    to choose additional conditions for each:</p>
            </div>

            <br/>

            <div className={s.text_box}>
                <p className={classNames(s.text, s.text_title)}>Secured Loans:</p>
                <p className={s.text}>These loans are issued against collateral in cryptocurrencies, stablecoins, NFTs,
                    and other digital assets. Collateral minimizes the risks for creditors and allows offering lower
                    interest rates to borrowers. Depending on the terms, the collateral can exceed the loan amount by
                    150-200%. For instance, a borrower might use their crypto assets to secure financing for business
                    projects. Webtransfer credit certificates can also act as collateral.</p>
            </div>

            <br/>

            <div className={s.text_box}>
                <p className={classNames(s.text, s.text_title)}>Unsecured Loans:</p>
                <p className={s.text}>Issued based on the borrower’s social credit rating without physical collateral.
                    These loans carry a higher risk for creditors, which is reflected in higher interest rates. You can
                    learn more about the social credit rating system and its role in evaluating borrowers in the
                    section <NavLink className={s.link}
                                     target={'_blank'} to={'/lk/credit-certificates'}>“Credit Scoring“</NavLink>.</p>
            </div>
            <div className={s.text_box}>
                <p className={s.text_box_li} style={{paddingLeft: '0px'}}>
                    <span className={s.text}>
                        <b>Guarantor Rating: </b>
                      This parameter assesses the number of guarantors a participant has in their credit union or partner network, reflecting the level of trust in the system and their ability to attract guarantors.</span>
                </p>
            </div>

            <div className={classNames(s.text_box, s.text_box_last)}>
                <p className={classNames(s.text, s.text_description)}>* The minimum loan amount is $50, the maximum is
                    $1000, with a maximum term of 30 days.</p>
            </div>
        </div>
    );
};

export default Borrowers;