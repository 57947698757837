import React, {useState} from 'react';
import s from './styles.module.css'
import TitleCabinet from "../../../commom/titleCabinet";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useGetLatestOperationsQuery} from "../../../store/profile.service";
import moment from "moment";
import {Pagination, useMediaQuery} from "@mui/material";

function createData(number, dateTime, type, comming, outgoing) {
    return {number, dateTime, type, comming, outgoing};
}

const rows = [
    createData('1221235', '04/08/2022 - 15:38', 'Пополнение счета Qadsan', '2 000', '-'),
    createData('1221235', '04/08/2022 - 15:38', 'Покупка токен-акций Pepsi', '2 321', '-'),
    createData('1221235', '04/08/2022 - 15:38', 'Покупка токен-акций Pepsi', '4 235', '3 293'),
    createData('1221235', '04/08/2022 - 15:38', 'Пополнение счета Qadsan', '344', '-'),
    createData('1221235', '04/08/2022 - 15:38', 'Покупка токен-акций Pepsi', '9 857', '-'),
];

const stylesHeaderTitle = {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '16.94px',
    color: '#828282'
}

const stylesRowTitle = (type = 1, typeSize = 1) => {

    return {
        fontFamily: 'Inter',
        fontSize:
            (typeSize === 1 && '14px') ||
            (typeSize === 2 && '16px')
        ,
        fontWeight: '400',
        lineHeight:
            (typeSize === 1 && '16.94px') ||
            (typeSize === 2 && '19.36px'),
        color:
            (type === 1 && '#333333') ||
            (type === 2 && '#828282') ||
            (type === 3 && '#333333') ||
            (type === 4 && '#16AE1E') ||
            (type === 5 && '#333333')
    }
}

const Operations = () => {
    const [page, setPage] = useState(1)
    const matches768 = useMediaQuery('(max-width:768px)');

    const {data, isLoading} = useGetLatestOperationsQuery({
        page: page
    }, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true,
    })
    console.log(data)
    return (
        <div className={s.operations}>
            <div className={s.header}>
                <TitleCabinet title={'Latest transactions'}/>

                <p className={s.link}>
                    {matches768 ? 'All' : 'All operations'}
                    <ArrowForwardIosIcon sx={{width: 'auto', height: '14px'}}/>
                </p>
            </div>

            <TableContainer component={Paper} sx={{
                border: 'none',
                boxShadow: 'none'
            }}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={stylesHeaderTitle}>№</TableCell>
                            <TableCell sx={stylesHeaderTitle} align="right">Date/Time</TableCell>
                            <TableCell sx={stylesHeaderTitle} align="right">Type of transaction</TableCell>
                            <TableCell sx={stylesHeaderTitle} align="right">Coming</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.items?.map((row, i) => (
                            <TableRow
                                key={i}
                                sx={{


                                    '&:last-child td, &:last-child th': {border: 0}
                                }}
                            >
                                <TableCell sx={{
                                    ...stylesRowTitle(1, 1),
                                }} component="th" scope="row">
                                    {i + 1}
                                </TableCell>

                                <TableCell sx={{
                                    ...stylesRowTitle(2, 1),
                                }} align="right">{moment(row.paymentDate)?.format('DD.MM.YYYY HH:mm')}</TableCell>
                                <TableCell
                                    sx={{
                                        ...stylesRowTitle(3, 2),
                                    }}
                                    align="right">{row?.paymentPurpose || '-'}</TableCell>
                                <TableCell
                                    sx={{
                                        ...stylesRowTitle(4, 2),
                                    }}
                                    align="right">{row.paymentSum}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className={s.pagination}>
                <Pagination count={data?.totalPages || 1} color="primary" page={page} onChange={(e, p) => {
                    setPage(p)
                }}/>
            </div>
        </div>
    );
};

export default Operations;