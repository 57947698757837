export function formatNumberWithSpaces(number = 0) {
    // Разделяем число на целую и дробную части
    const [integerPart, fractionalPart] = number.toString().split('.');

    // Форматируем целую часть
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    // Возвращаем результат с дробной частью (если есть)
    return fractionalPart ? `${formattedIntegerPart}.${fractionalPart}` : formattedIntegerPart;

}
