import React from 'react';
import s from "./styles.module.css";
import {List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import {NavLink, useLocation} from "react-router-dom";
import classNames from "classnames";
import {navigateItem} from "./index";

const NavigateItems = () => {
    const {pathname} = useLocation()
    const pathnameLast = pathname?.split('/')?.at(-1) === 'lk' ? '' : pathname?.split('/')?.at(-1)

    return (
        <div className={s.navigate_box}>
            <List>
                {navigateItem?.map((el, i) => {
                    return <NavLink to={el.link} key={i}>
                        <ListItem disablePadding sx={{
                            position: 'relative'
                        }}>
                            {pathnameLast === el.link && <div className={s.line}/>}
                            <ListItemButton color={'#fff'} sx={{
                                padding: '16px',
                                background: pathnameLast === el.link && 'linear-gradient(90deg, rgba(7, 150, 199, 0.33) 0%, rgba(7, 150, 199, 0) 100%)',
                                position: 'relative',
                                transition: '.3s all',
                                borderBottom: '1px solid #E0E0E0',
                                "&:hover": {
                                    background: pathnameLast !== el.link ? 'rgba(7,150,199,0.05)' : 'linear-gradient(90deg, rgba(7, 150, 199, 0.33) 0%, rgba(7, 150, 199, 0) 100%)',
                                },
                                "& .MuiTouchRipple-child": {
                                    backgroundColor: '#0796C7 !important',
                                }
                            }}>
                                <div className={classNames(s.icon, pathnameLast === el.link && s.active_icon)}>
                                    {el.icon}
                                </div>
                                <ListItemText sx={{
                                    '& span': {
                                        fontFamily: 'Inter,sans-serif',
                                        fontSize: '16px',
                                        fontWeight: '500',
                                        lineHeight: '19.36px',
                                        textAlign: 'left',
                                        transition: '.3s all',
                                        color: pathnameLast === el.link ? '#333333' : '#BDBDBD'
                                    }
                                }} primary={el?.title}/>
                            </ListItemButton>
                        </ListItem>
                    </NavLink>
                })}
            </List>

        </div>
    );
};

export default NavigateItems;