import React from 'react';
import s from './styles.module.css'
import classNames from "classnames";

const Lenders = () => {
    return (
        <div className={s.main}>
            <div className={s.text_box}>
                <p className={s.text}>Lenders in the Webtransfer network form the foundation of an ecosystem that
                    enables borrowers to
                    implement economic initiatives and strive for development. As a creditor, you have the opportunity
                    to
                    invest funds in various types of credit liquidity pools, each offering its own terms and levels of
                    risk:</p>
            </div>
            <div className={s.text_box}>
                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Collateralized pools: </b>
                        Your investments are
                        protected by crypto
                        assets,
                        which reduce risk and enable more favorable interest rates.</span>
                </li>
                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Uncollateralized pools: </b>
                        Credit issuance is
                        based on borrowers’
                        social
                        ratings, resulting in higher interest rates due to increased risk.</span>
                </li>
                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Mixed Pools: </b>
                        These combine elements of both
                        types to balance
                        yield and
                        risk.</span>
                </li>
            </div>
            <div className={s.text_box}>
                <p className={s.text}>As a creditor, you can also make use of innovative tools such as:</p>
            </div>
            <div className={s.text_box}>
                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Secure Loans: </b>
                      A special category of secured loans further protected by an insurance pool, which provides full or partial loss coverage.</span>
                </li>
                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Direct Loans: </b>
                       Direct credit deals between users without additional insurance, implying various levels of risk depending on loan conditions.</span>
                </li>
            </div>
            <div className={s.text_box}>
                <p className={s.text}>Investing in credit pools is facilitated through credit certificates (LP NFT
                    tokens), which serve as proof of the invested share and the right to receive a portion of the
                    interest income. Creditors can manage their investments through the platform interface, adding or
                    withdrawing funds from pools, as well as trading credit certificates on the secondary market.</p>
            </div>
            <div className={s.text_box}>
                <p className={s.text}>The maximum amount and term of the loan are defined in accordance with the
                    platform’s policy: up to 1,000 US dollars in stablecoin equivalent for a period of up to 30 days.*
                    This provides flexibility and control over your investments, with the simultaneous support of
                    transparency and security for all transactions within the decentralized Webtransfer ecosystem.</p>
            </div>
            <div className={classNames(s.text_box, s.text_box_last)}>
                <p className={classNames(s.text, s.text_description)}>* This limitation may be changed by the decision
                    of WEB token holders.</p>
            </div>
        </div>
    );
};

export default Lenders;