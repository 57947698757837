import React from 'react';
import s from './styles.module.css'

const TitleCabinet = ({title}) => {

    return (
        <h2 className={s.title}>
            {title}
        </h2>
    );
};

export default TitleCabinet;