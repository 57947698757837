import {setupListeners} from "@reduxjs/toolkit/query";
import {configureStore} from "@reduxjs/toolkit";
import {profileApi} from "./profile.service";

export const storeRedux = configureStore({
    reducer: {
        [profileApi.reducerPath]: profileApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            profileApi.middleware,
        ),
})

setupListeners(storeRedux.dispatch)
