// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_btn__1YW-u {
    height: 100%;
    width: 100%;
    border-radius: 6px;
    background: #0073FF;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    color: #fff;
    cursor: pointer;
    transition: .4s all;
}

.styles_btn__1YW-u:hover {
    background: #3a8ff6;
}`, "",{"version":3,"sources":["webpack://./src/commom/button/styles.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;;IAEvB,8BAA8B;IAC9B,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,WAAW;IACX,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".btn {\n    height: 100%;\n    width: 100%;\n    border-radius: 6px;\n    background: #0073FF;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    font-family: Inter, sans-serif;\n    font-size: 16px;\n    font-weight: 700;\n    line-height: 19.36px;\n    color: #fff;\n    cursor: pointer;\n    transition: .4s all;\n}\n\n.btn:hover {\n    background: #3a8ff6;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `styles_btn__1YW-u`
};
export default ___CSS_LOADER_EXPORT___;
