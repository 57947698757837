import {fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import Cookies from 'js-cookie';

const BASE_URL = 'https://api.webtransfer.com/api/'

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,
    credentials: 'include',
    prepareHeaders: (headers, {getState}) => {
        headers.set("Content-Type", "application/json");
        if (Cookies.get('idToken')) {
            headers.set("Authorization", `Bearer ${Cookies.get('idToken')}`);
        }
        return headers
    },
})

export const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    return result
}
