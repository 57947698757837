// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_content__kGgB-{
    width: 100%;
    display: grid;
    gap: 14px;
}`, "",{"version":3,"sources":["webpack://./src/page/auth/modalReg/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,SAAS;AACb","sourcesContent":[".content{\n    width: 100%;\n    display: grid;\n    gap: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `styles_content__kGgB-`
};
export default ___CSS_LOADER_EXPORT___;
