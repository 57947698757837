import React, {useContext, useEffect, useState} from 'react';
import s from './styles.module.css'
import {ReactComponent as Icon1} from "../../assets/cabinet/icon1.svg";
import {ReactComponent as Icon2} from "../../assets/cabinet/icon2.svg";
import {ReactComponent as Icon3} from "../../assets/cabinet/icon3.svg";
import {Avatar, Button, IconButton, Tooltip, useMediaQuery} from "@mui/material";
import {UserContext} from "../lkContainer";
import Input from "../../commom/input";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {toast} from "react-toastify";
import News from "./news";
import Operations from "./operations";
import {useLocation} from "react-router-dom";
import {AuthContext} from "../../App";
import TitleCabinet from "../../commom/titleCabinet";
import TwitterContent from "../../component/twitterContent";

const links = [
    {title: 'Personal data', icon: <Icon1/>},
    {title: 'Documentation', icon: <Icon2/>},
    {title: 'Account settings', icon: <Icon3/>},
]

const Cabinet = () => {
    const location = useLocation()
    const query_700 = useMediaQuery('(max-width:780px)');

    const {user} = useContext(UserContext)
    const {profile, provider} = useContext(AuthContext)
    const [refLink, setRefLink] = useState('https://dreams-eta.vercel.app/register/test22')

    const handleCopyText = async (text) => {
        try {
            await navigator.clipboard.writeText(text)
            toast.success('Copied')
        } catch (e) {
            console.log(e)
            toast.error('Error copy')
        }
    }

    useEffect(() => {
        const base_url = window.location.origin;
        if (profile?.number) {
            setRefLink(`${base_url}?inviteID=${profile?.number}`)
        } else {
            setRefLink(`empty`)
        }

    }, [profile?.number])

    console.log(user)
    const img_url = user?.profileImage ? user?.profileImage.replaceAll('s96', 's400') : user?.profileImage


    return (
        <div className={s.wrapper}>
            <div className={s.wrapper_left}>
                <div className={s.content}>
                    <div className={s.left_side}>
                        <div className={s.left_side_top}>
                            {user?.profileImage ?
                                <Avatar sx={{width: '60px', height: '60px', borderRadius: '5px'}} alt={'avatar'}
                                        src={img_url}/> :
                                <Avatar sx={{width: '60px', height: '60px', borderRadius: '5px'}} alt={'avatar'}/>}
                            <div className={s.left_side_top_right}>
                                <p className={s.left_side_top_right_fullname}>{user?.name}</p>
                                <p className={s.left_side_top_right_email}>{user?.email}</p>
                            </div>
                        </div>
                        <div className={s.left_side_bottom}>
                            <div className={s.input}>
                                <div className={s.wallet}>
                                    <Input value={user?.address_crypto} style={{paddingRight: `182px`}}
                                           title={'Address:'} disabled={true}/>
                                    <div className={s.balance_wallet}>
                                        <p className={s.balance_sum}>{`Balance: ${+user?.balance}$`}</p>
                                    </div>
                                </div>
                                {query_700 ? <Button fullWidth variant="outlined"
                                                     onClick={() => handleCopyText(user?.address_crypto)}>Copy
                                        address</Button> :
                                    <Tooltip title={'Copy'}>
                                        <IconButton sx={{marginBottom: '5px'}}
                                                    onClick={() => handleCopyText(user?.address_crypto)}>
                                            <ContentCopyIcon/>
                                        </IconButton>
                                    </Tooltip>}
                            </div>
                            <div className={s.input}>

                                <Input value={refLink} title={'Referral link:'} disabled={true}
                                       placeholder={'https://dreams-eta.vercel.app/register/test22'}/>
                                {query_700 ?
                                    <Button fullWidth variant="outlined" onClick={() => handleCopyText(refLink)}>Copy
                                        referral link</Button> : <Tooltip title={'Copy'}>
                                        <IconButton sx={{marginBottom: '5px'}} onClick={() => handleCopyText(refLink)}>
                                            <ContentCopyIcon/>
                                        </IconButton>
                                    </Tooltip>}
                            </div>
                        </div>
                    </div>
                </div>
                <Operations/>
            </div>

            <div className={s.news}>
                <div className={s.news_header}>
                    <TitleCabinet title={'Latest news'}/>
                </div>
                <TwitterContent/>
            </div>
        </div>

    );
};

export default Cabinet;
