import React, {createContext, useContext, useEffect, useState} from 'react';
import s from './styles.module.css'
import {Outlet, useLocation, Navigate as NavigateRoute} from "react-router-dom";
import RPC from "../../solanaRPC";
import Web3RPC from "../../web3RPC";
import {AuthContext} from "../../App";
import Navigate, {navigateItem} from "../../component/navigate";
import {formatNumberWithSpaces} from "../../utils/formatNumber";
import axios from "axios";
import {Skeleton, useMediaQuery} from "@mui/material";
import Button from "../../commom/button";

export const UserContext = createContext(null)

const LkContainer = () => {

    const location = useLocation()
    const {pathname} = location

    const pathnameLast = pathname?.split('/')?.at(-1) === 'lk' ? '' : pathname?.split('/')?.at(-1)

    const titlePage = navigateItem?.find((f) => f.link === pathnameLast)?.title

    const {web3auth, provider, select, loggedIn} = useContext(AuthContext)

    const classRoot = +select === 2 ? new RPC(provider) : new Web3RPC(provider)

    const [user, setUser] = useState({})
    const [currency, setCurrency] = useState([])
    const [loadingCurrency, setLoadingCurrency] = useState(true)

    useEffect(() => {
        const getFullData = async () => {
            if (!web3auth) {
                return;
            } else {
                const rpc = classRoot;
                console.log(rpc)
                const userInfo = await web3auth.getUserInfo();
                const balance = await rpc.getBalance();
                // const privateKey = await rpc.getPrivateKey();
                const chainId = await rpc.getChainId();
                const address = await rpc.getAccounts();
                console.log(chainId)
                console.log(balance)
                setUser({
                    ...userInfo,
                    balance: balance,
                    // privateKey: privateKey,
                    networkType: chainId,
                    address_crypto: address[0]
                })
            }
        }
        if (loggedIn) {
            getFullData()
        }
    }, [web3auth])

    useEffect(() => {
        const getCurrency = async () => {
            setLoadingCurrency(true)
            try {
                const {data: eth} = await axios.get('https://api.coinbase.com/v2/prices/ETH-USD/spot')
                const {data: sol} = await axios.get('https://api.coinbase.com/v2/prices/SOL-USD/spot')
                const {data: bnb} = await axios.get('https://api.coinbase.com/v2/prices/BNB-USD/spot')

                const ethData = {
                    title: `${eth?.data?.base}/${eth?.data?.currency}`,
                    amount: Number(eth?.data?.amount)?.toFixed(2)
                }
                const solData = {
                    title: `${sol?.data?.base}/${sol?.data?.currency}`,
                    amount: Number(sol?.data?.amount)?.toFixed(2)
                }
                const bnbData = {
                    title: `${bnb?.data?.base}/${bnb?.data?.currency}`,
                    amount: Number(bnb?.data?.amount)?.toFixed(2)
                }

                setCurrency([ethData, solData, bnbData])
            } catch (e) {
                console.log(e)
            } finally {
                setLoadingCurrency(false)
            }
        }
        getCurrency()
    }, [])
    console.log(loggedIn)
    if (!loggedIn) {
        return <NavigateRoute to="/" replace state={{from: location}}/>
    }

    return (
        <UserContext.Provider value={{
            user: user,
            classRoot: classRoot,
        }}>
            <div className={s.container}>

                <Navigate title={titlePage}/>

                <div className={s.content}>
                    <div className={s.curses} style={{
                        // padding: loadingCurrency && '0'
                    }}>
                        <h2 className={s.title_page}>{titlePage}</h2>

                        <div className={s.btns}>
                            <Button>TOP UP</Button>
                            <Button>TRANSFER</Button>
                        </div>

                        {loadingCurrency ?
                            <Skeleton variant="rectangular"
                                      sx={{width: '340px', borderRadius: '5px', height: '100%'}}/> :
                            <div className={s.header}>
                                {currency?.map((el, i) => {
                                    return <div key={i} className={s.currency_item}>
                                        <p className={s.currency_item_title}>{el.title}</p>
                                        <p className={s.currency_item_amount}>{`$ ${formatNumberWithSpaces(el.amount ? +el.amount : 0)}`}</p>
                                    </div>
                                })}
                            </div>}

                    </div>
                    <div className={s.outlet}>
                        <Outlet/>
                    </div>
                </div>
            </div>
        </UserContext.Provider>
    );
};

export default LkContainer;
