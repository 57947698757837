// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_input_box__wf--B {
    width: 100%;
}

.styles_title__oGpOr {
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
    color: #333333;
    margin-bottom: 14px;
}

.styles_input__SZ7XQ {
    width: 100%;
    outline: none;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    height: 50px;
    padding: 0 17px;
    background: #fff;

    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: left;
    color: #656565;
}

.styles_input__SZ7XQ::placeholder {
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: left;
    color: #BDBDBD;
}`, "",{"version":3,"sources":["webpack://./src/commom/input/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,8BAA8B;IAC9B,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,gBAAgB;IAChB,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,gBAAgB;;IAEhB,8BAA8B;IAC9B,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,8BAA8B;IAC9B,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".input_box {\n    width: 100%;\n}\n\n.title {\n    font-family: Inter, sans-serif;\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 19.36px;\n    text-align: left;\n    color: #333333;\n    margin-bottom: 14px;\n}\n\n.input {\n    width: 100%;\n    outline: none;\n    border: 1px solid #E0E0E0;\n    border-radius: 5px;\n    height: 50px;\n    padding: 0 17px;\n    background: #fff;\n\n    font-family: Inter, sans-serif;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 19.36px;\n    text-align: left;\n    color: #656565;\n}\n\n.input::placeholder {\n    font-family: Inter, sans-serif;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 19.36px;\n    text-align: left;\n    color: #BDBDBD;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input_box": `styles_input_box__wf--B`,
	"title": `styles_title__oGpOr`,
	"input": `styles_input__SZ7XQ`
};
export default ___CSS_LOADER_EXPORT___;
