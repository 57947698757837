import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQueryWithReauth} from "../api/baseQuery";
import {registration} from "../requests/requests";


export const profileApi = createApi({
    reducerPath: 'profileApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['profileApi'],
    endpoints: (build) => ({
        getProfile: build.mutation({
            query: () => ({
                url: `profile`,
                method: 'GET',
            }),
        }),
        registration: build.mutation({
            query: (ID) => {
                const path = ID ? `users/register?ParentNumber=${ID}` : `users/register`
                return {
                    url: path,
                    method: 'POST',
                }
            },
        }),
        getLatestOperations: build.query({
            query: ({page}) => {
                return {
                    url: `Finance/transfers-list?AccountType=10&PageSize=14&PageNumber=${page}`,
                    method: 'GET',
                }
            },
        }),
    })
})

export const {
    useGetProfileMutation,
    useRegistrationMutation,
    useGetLatestOperationsQuery
} = profileApi;
