import './App.css';
import {Web3AuthNoModal} from "@web3auth/no-modal";

import {CHAIN_NAMESPACES, WALLET_ADAPTERS} from "@web3auth/base";
import {createContext, useEffect, useState} from "react";
import {LinearProgress} from "@mui/material";
import useLocalStorage from "use-local-storage";
import {useNavigate, useSearchParams} from "react-router-dom";
import Routers from "./routers";
import ModalReg from "./page/auth/modalReg";
import {OpenloginAdapter} from "@web3auth/openlogin-adapter";
import {useGetProfileMutation} from "./store/profile.service";
import Cookies from 'js-cookie';
import {web3AuthConfig} from "./config/web3auth";

export const AuthContext = createContext(null)

function App() {
    const navigate = useNavigate()
    const [params, setParams] = useSearchParams()
    const [getProfile] = useGetProfileMutation()

    const [openModalReg, setOpenModalReg] = useState(false)
    const [provider, setProvider] = useState(null);
    const [loggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true)
    const [select, setSelect] = useLocalStorage('networkType', 1)
    const [web3auth, setWeb3auth] = useState(null);
    const [profile, setProfile] = useState({})

    const handleLogin = () => {
        // http://localhost:3000?inviteID=100011
        // setLoggedIn(true);
        // setLoading(false)

        getProfile()
            .unwrap()
            .then((res) => {
                console.log(res)
                setProfile(res)
                setLoggedIn(true);
                setLoading(false)
            })
            .catch((e) => {
                console.log(e)
                if (e?.status === 403) {
                    setOpenModalReg(true);
                }
                setLoading(false)
            })
    }

    const loginGoogle = async () => {
        if (!web3auth) {
            return;
        }
        await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
            loginProvider: "google",
        });
        if (web3auth.status === "connected") {
            setProvider(web3auth?.provider);
            handleLogin()
        }
    };
    const loginDiscord = async () => {
        if (!web3auth) {
            return;
        }
        await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
            loginProvider: "discord",
        });
        if (web3auth.status === "connected") {
            setProvider(web3auth?.provider);
            handleLogin()
        }
    };
    const loginTwitter = async () => {
        if (!web3auth) {
            return;
        }
        await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
            loginProvider: "twitter",
        });
        if (web3auth.status === "connected") {
            setProvider(web3auth?.provider);
            handleLogin()
        }
    };
    const loginFacebook = async () => {
        if (!web3auth) {
            return;
        }
        await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
            loginProvider: "facebook",
        });
        if (web3auth.status === "connected") {
            setProvider(web3auth?.provider);
            handleLogin()
        }
    };
    const loginEmail = async (email) => {
        if (!web3auth) {
            return;
        }
        await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
            loginProvider: "email_passwordless",
            extraLoginOptions: {
                login_hint: email,
            },
        });
        if (web3auth.status === "connected") {
            setProvider(web3auth?.provider);
            handleLogin()
        }
    };

    useEffect(() => {
        const init = async () => {
            try {
                const web3auth = new Web3AuthNoModal(web3AuthConfig(+select));
                setWeb3auth(web3auth);

                const openloginAdapter = new OpenloginAdapter({
                    loginSettings: {
                        mfaLevel: "none",
                        appState: params.get('inviteID')
                    },
                    adapterSettings: {
                        uxMode: "redirect", // "redirect" | "popup"
                    },
                });
                web3auth.configureAdapter(openloginAdapter);

                await web3auth.init();

                setProvider(web3auth.provider);
                console.log(web3auth)
                if (web3auth.status === "connected") {
                    const {idToken} = await web3auth.authenticateUser();
                    Cookies.set('idToken', idToken)
                    handleLogin()
                } else {
                    setLoading(false)
                }
            } catch (error) {
                console.error(error);
            }
        };

        init();
    }, [select]);

    const logout = async () => {
        if (!web3auth) {
            return;
        }
        await web3auth.logout();
        await web3auth.clearCache();
        setLoggedIn(false);
    };

    if (loading) {
        return <LinearProgress/>
    }

    return (
        <AuthContext.Provider value={{
            setSelect: setSelect,
            select: select,
            loginGoogle: loginGoogle,
            loginFacebook: loginFacebook,
            loginTwitter: loginTwitter,
            loginDiscord: loginDiscord,
            loginEmail: loginEmail,
            provider: provider,
            logout: logout,
            web3auth: web3auth,
            loggedIn: loggedIn,
            setLoggedIn: setLoggedIn,
            profile: profile,
            setProfile: setProfile
        }}>
            {openModalReg && <ModalReg open={openModalReg} handleCloseModal={async () => {
                await logout()
                setOpenModalReg(false)
            }} handleClose={() => {
                setOpenModalReg(false)

            }}/>}
            <div className="container">
                <Routers/>
            </div>
        </AuthContext.Provider>
    );
}

export default App;
