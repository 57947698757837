import React from 'react';
import s from './styles.module.css'
import classNames from "classnames";

const Insurance = () => {
    return (
        <div className={s.main}>
            <div className={s.text_box}>
                <p className={classNames(s.text, s.text_title)} style={{fontWeight: 900, fontSize: '24px'}}>
                    Enhance the protection of your investments with added security through Webtransfer.
                </p>
                <p className={s.text}>
                    Webtransfer’s Insurance Pool is established to mitigate potential financial losses for lenders in
                    case loans are not repaid. A portion of the interest from each issued loan is allocated to this
                    reserve, providing an additional layer of protection:
                </p>
            </div>

            <div className={s.text_box}>
                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Loss Compensation: </b>
                    In the event of a borrower’s default, the insurance fund disburses compensations, minimizing lenders’ financial risks.</span>
                </li>
                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Stability of the Credit Network: </b>
                      The presence of the insurance reserve boosts investors’ confidence, maintaining stability throughout the system.</span>
                </li>
                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Decentralized Management: </b>
                        The pool is managed using blockchain technology, ensuring transparency in the allocation and use of resources.</span>
                </li>
            </div>

            <div className={s.text_box}>
                <p className={s.text}>
                    Upon successful loan repayment, 25% of the interest received is allocated to the insurance pool.
                    This level of insurance guarantees full compensation for losses in the event of borrower default.
                </p>
                <p className={s.text}>
                    The maximum amount of insurance paid out per loan is limited to $1,000, which corresponds to the
                    maximum size of a single loan. Interest on the loan is not covered by the insurance pool.
                </p>
                <p className={s.text}>
                    In the event of borrower default, the lender’s losses are compensated from the funds in the
                    insurance pool. Compensation is provided by repurchasing credit certificates secured by the issued
                    loan, with an addition of 5% per annum to the amount of the unpaid debt.
                </p>
                <p className={s.text}>
                    Investing with Webtransfer becomes more secure with the insurance pool, offering lenders an
                    additional level of confidence and peace of mind.
                </p>
                <p className={s.text}>
                    The Webtransfer Insurance Pool plays a pivotal role in maintaining financial stability and
                    protecting lenders by accumulating resources through commissions and the pool’s investment
                    activities. At the end of the year, 50% of the collected funds are distributed among Webtransfer
                    token (WEB) holders as dividends, strengthening the network’s long-term financial stability. The
                    management of the pool is executed through decentralized voting by WEB token holders, enhancing
                    transparency and trust in the insurance system.
                </p>
            </div>

            <div className={classNames(s.text_box, s.text_box_last)}>
                <p className={classNames(s.text, s.text_description)}>Investing in Webtransfer is associated with an
                    extra layer of protection, allowing you to invest your funds with greater assurance in the security
                    of your capital.</p>
            </div>
        </div>
    );
};

export default Insurance;