import React from 'react';
import s from './styles.module.css'

const TwitterContent = () => {
    return (
        <div className={s.box}>
            <div className="elfsight-app-16e8217d-10fe-4be1-9afa-5bc8f0e96504" data-elfsight-app-lazy></div>
        </div>
    );
};

export default TwitterContent;