import React, {useContext, useState} from 'react';
import s from "./styles.module.css";
import {Avatar, IconButton, Tooltip, useMediaQuery} from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ReactFlagsSelect from "react-flags-select";
import {inDev} from "./index";
import {AuthContext} from "../../App";
import {UserContext} from "../../page/lkContainer";

const Settings = () => {
    const matches768 = useMediaQuery('(max-width:768px)');

    const {logout, profile} = useContext(AuthContext)
    const {user} = useContext(UserContext)

    const [selected, setSelected] = useState("DE");

    const img_url = user?.profileImage ? user?.profileImage.replaceAll('s96', 's400') : user?.profileImage

    return (
        <div className={s.avatar_box}>
            {!matches768 && <>
                {img_url ?
                    <Avatar sx={{width: '200px', height: '200px', borderRadius: '5px'}} alt={'avatar'} src={img_url}/> :
                    <Avatar sx={{width: '200px', height: '200px', borderRadius: '5px'}} alt={'avatar'}/>}
            </>}


            <div className={s.sub_info}>
                <div className={s.icon_box}>
                    <Tooltip title={'Message'} arrow>
                        <IconButton onClick={inDev}>
                            <EmailOutlinedIcon sx={{color: '#828282'}}/>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={'Notifications'} arrow>
                        <IconButton onClick={inDev}>
                            <NotificationsNoneOutlinedIcon sx={{color: '#828282'}}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={'Logout'} arrow onClick={logout}>
                        <IconButton>
                            <LogoutOutlinedIcon sx={{color: '#828282'}}/>
                        </IconButton>
                    </Tooltip>
                </div>
                <p className={s.text}>
                    <b>{`$${+profile?.innerAccountBalance}`}</b>
                </p>
                <div onClick={(e) => {
                    e.stopPropagation()
                }}>
                    <ReactFlagsSelect
                        className={s.selected_lang}
                        selected={selected}
                        countries={["US", "GB", "FR", "DE", "RU"]}
                        customLabels={{US: "EN-US", GB: "EN-GB", FR: "FR", DE: "DE", RU: "RU"}}
                        placeholder="Select Language"
                        onSelect={(code, e) => {
                            setSelected(code)
                            console.log(e)
                            console.log(code)
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default Settings;