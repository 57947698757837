// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_main__0sa5E {
    width: 100%;
    min-height: 100%;
    height: 100%;

    box-shadow: 0 0 6px 0 #ddd;
    background: #fff;
    border-radius: 5px;
    padding: 36px 31px;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.styles_text_box__xv6fu {
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.styles_text__pHtz2 {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 140%;
    color: #333333;

}

.styles_text_box_li__8KIzX {
    color: #086A8C;
    list-style-type: disc;
    padding-left: 24px;
    font-weight: 900;
}

.styles_text__pHtz2 b {
    font-weight: 900;
    color: #086A8C;
}

.styles_text_description__7x2iS {
    font-style: italic;
    font-size: 16px;
}

.styles_text_box_last__iXcpq {
    margin-top: auto;
}

.styles_text_title__oqtEx {
    font-size: 20px;
    font-weight: 600;
    color: #086A8C;
}

.styles_link__mVttU {
    font-style: italic;
    font-weight: 600;
    color: #dea436;
}`, "",{"version":3,"sources":["webpack://./src/page/borrowers/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,YAAY;;IAEZ,0BAA0B;IAC1B,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,gCAAgC;IAChC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;;AAElB;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".main {\n    width: 100%;\n    min-height: 100%;\n    height: 100%;\n\n    box-shadow: 0 0 6px 0 #ddd;\n    background: #fff;\n    border-radius: 5px;\n    padding: 36px 31px;\n    display: flex;\n    flex-direction: column;\n    gap: 14px;\n}\n\n.text_box {\n    display: flex;\n    flex-direction: column;\n    gap: 14px;\n}\n\n.text {\n    font-family: 'Inter', sans-serif;\n    font-size: 18px;\n    font-weight: 400;\n    line-height: 140%;\n    color: #333333;\n\n}\n\n.text_box_li {\n    color: #086A8C;\n    list-style-type: disc;\n    padding-left: 24px;\n    font-weight: 900;\n}\n\n.text b {\n    font-weight: 900;\n    color: #086A8C;\n}\n\n.text_description {\n    font-style: italic;\n    font-size: 16px;\n}\n\n.text_box_last {\n    margin-top: auto;\n}\n\n.text_title {\n    font-size: 20px;\n    font-weight: 600;\n    color: #086A8C;\n}\n\n.link {\n    font-style: italic;\n    font-weight: 600;\n    color: #dea436;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `styles_main__0sa5E`,
	"text_box": `styles_text_box__xv6fu`,
	"text": `styles_text__pHtz2`,
	"text_box_li": `styles_text_box_li__8KIzX`,
	"text_description": `styles_text_description__7x2iS`,
	"text_box_last": `styles_text_box_last__iXcpq`,
	"text_title": `styles_text_title__oqtEx`,
	"link": `styles_link__mVttU`
};
export default ___CSS_LOADER_EXPORT___;
