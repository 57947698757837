import React from 'react';
import s from './styles.module.css'
import classNames from "classnames";

const CreditCertificates = () => {
    return (
        <div className={s.main}>
            <div className={s.text_box}>
                <p className={s.text}>
                    Credit certificates, non-fungible tokens (NFT) associated with liquidity credit pools, are used to
                    represent shares in these pools. Each certificate is unique and provides its holder with several
                    important rights and benefits:
                </p>
            </div>

            <div className={s.text_box}>
                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Verification of the liquidity share contributed </b>
                    to a specific credit pool.
                    </span>
                </li>
                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Right to receive a portion of the interest income </b>
                         from loans issued from the pool, proportional to the contributed liquidity.
               </span>
                </li>
                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Ability to manage liquidity — </b>
                     adding or withdrawing funds from the pool at any time.</span>
                </li>

                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Option to trade Credit Certificates </b>
                     among all holders on the secondary market.</span>
                </li>


            </div>

            <div className={classNames(s.text_box,)}>
                <p className={classNames(s.text, s.text_title)}>Expanded Uses of Credit Certificates</p>
                <p className={s.text}>
                    Beyond their primary functions, Credit Certificates (Liquidity Pool NFT tokens) offer Webtransfer
                    participants broader opportunities for interaction with the platform’s ecosystem:
                </p>
                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Use as collateral for loans: </b>
          Credit certificates can be directed into specially created pools that offer various investment strategies or focus on specific market segments, allowing holders to diversify their investments and engage in new financial projects.
               </span>
                </li>

                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Use as a tool for loans by other participants: </b>
                     Certificates can serve not only as collateral for the holders themselves but can also be offered in pools where other participants can borrow against their own assets. This approach helps create additional demand for credit certificates and increases their liquidity on the platform.</span>
                </li>
            </div>

            <div className={classNames(s.text_box, s.text_box_last)}>
                <p className={s.text}>
                    These additional features of credit certificates significantly expand their utility within the
                    Webtransfer ecosystem, making them a multifunctional tool for managing personal finances, investing,
                    and obtaining credit. They enable holders to actively participate in the platform’s economy,
                    maximizing the benefits from their investments and providing new opportunities for growth and
                    development across the ecosystem.
                </p>
                <p className={s.text}>
                    Thus, NFT Credit Certificates not only act as a tokenized digital right to ownership of a share in a
                    credit pool and its associated interest income streams but also provide a high level of security and
                    convenience in managing investments. Trading these certificates on the secondary market enhances
                    liquidity and offers Webtransfer ecosystem participants additional opportunities for interaction and
                    income generation.
                </p>
            </div>
        </div>
    );
};

export default CreditCertificates;