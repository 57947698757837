// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_operations__EKQqc {
    box-shadow: 0 0 6px 0 #ddd;
    background: #fff;
    border-radius: 5px;
    width: 100%;
    padding: 36px 31px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: 100%;
}

.styles_header__cQ1O2 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.styles_link__b94Ym {
    cursor: pointer;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 6px;
    color: #828282;
    transition: .4s all;
}

.styles_link__b94Ym svg {
    fill: #828282;
    transition: .3s all;
}

.styles_link__b94Ym:hover {
    color: rgba(8, 106, 140, 0.8);
}

.styles_link__b94Ym:hover svg {
    fill: rgba(8, 106, 140, 0.8);
}

.styles_pagination__yKSXE {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: auto;
    padding-top: 16px;
}`, "",{"version":3,"sources":["webpack://./src/page/cabinet/operations/styles.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,8BAA8B;IAC9B,SAAS;AACb;;AAEA;IACI,eAAe;IACf,8BAA8B;IAC9B,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".operations {\n    box-shadow: 0 0 6px 0 #ddd;\n    background: #fff;\n    border-radius: 5px;\n    width: 100%;\n    padding: 36px 31px;\n    display: flex;\n    flex-direction: column;\n    gap: 40px;\n    height: 100%;\n}\n\n.header {\n    display: flex;\n    width: 100%;\n    align-items: center;\n    justify-content: space-between;\n    gap: 15px;\n}\n\n.link {\n    cursor: pointer;\n    font-family: Inter, sans-serif;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 16.94px;\n    text-align: left;\n    display: flex;\n    align-items: center;\n    gap: 6px;\n    color: #828282;\n    transition: .4s all;\n}\n\n.link svg {\n    fill: #828282;\n    transition: .3s all;\n}\n\n.link:hover {\n    color: rgba(8, 106, 140, 0.8);\n}\n\n.link:hover svg {\n    fill: rgba(8, 106, 140, 0.8);\n}\n\n.pagination {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    margin-top: auto;\n    padding-top: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"operations": `styles_operations__EKQqc`,
	"header": `styles_header__cQ1O2`,
	"link": `styles_link__b94Ym`,
	"pagination": `styles_pagination__yKSXE`
};
export default ___CSS_LOADER_EXPORT___;
