import React, {useContext, useState} from 'react';
import {AuthContext} from "../../App";
import s from './styles.module.css'
import {Navigate, useLocation} from "react-router-dom";

import logo from '../../assets/logo'
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {ReactComponent as GoogleIcon} from "../../assets/login/google.svg";
import {ReactComponent as TwitterIcon} from "../../assets/login/twitter.svg";
import {ReactComponent as DiscordIcon} from "../../assets/login/discord.svg";
import {ReactComponent as FacebookIcon} from "../../assets/login/facebook.svg";

const Auth = () => {
    const [email, setEmail] = useState('')
    const location = useLocation()
    const {
        loginGoogle,
        loginDiscord,
        loginTwitter,
        loginFacebook,
        loginEmail,
        setSelect,
        select,
        loggedIn
    } = useContext(AuthContext)

    if (loggedIn) {
        return <Navigate to="/lk" replace state={{from: location}}/>
    }
    return (
        <div className={s.auth}>
            <div className={s.auth_box}>
                <div className={s.header}>
                    <h1 className={s.title}>Welcome to</h1>
                    <img className={s.logo} src={logo} alt="logo"/>
                </div>

                <div className={s.wallet}>
                    <p className={s.description}>Create your wallet in one click</p>
                    <FormControl fullWidth size={'small'}>
                        <InputLabel id="demo-simple-select-label">Wallet</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={select}
                            label="Wallet"
                            onChange={(e) => {
                                setSelect(e.target.value)
                            }}
                        >
                            <MenuItem value={1}>Polygon</MenuItem>
                            <MenuItem value={2}>Solana</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className={s.btns}>
                    <div className={s.btn} onClick={loginGoogle}><GoogleIcon/></div>
                    <div className={s.btn} onClick={loginTwitter}><TwitterIcon/></div>
                    <div className={s.btn} onClick={loginDiscord}><DiscordIcon/></div>
                    <div className={s.btn} onClick={loginFacebook}><FacebookIcon/></div>
                </div>

                <div className={s.or}>
                    <div className={s.line}/>
                    <p className={s.or_title}>or</p>
                    <div className={s.line}/>
                </div>

                <div className={s.write_input}>
                    <input type="text" placeholder={'Enter your email'} value={email}
                           onChange={(e) => setEmail(e.target.value)} className={s.input}/>

                    <button className={s.btn_login} disabled={!email} onClick={() => loginEmail(email)}>
                        Continue with Email
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Auth;
