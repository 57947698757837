// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_header__Ekjwc {
    position: fixed;
    top: 0;
    border-bottom: 1px solid #E0E0E0;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px;
    z-index: 7;
    background: #fff;
}

.styles_title__DC3mB {
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
    color: #086A8C;
}

.styles_burger__jRrqF {
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.styles_navigate__pb0QO {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    /*overflow: auto;*/
    padding: 16px 0 0 0;
}

.styles_logo__Ld28m {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/component/navigate/headerNavigate/styles.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,gCAAgC;IAChC,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;IACf,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,8BAA8B;IAC9B,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".header {\n    position: fixed;\n    top: 0;\n    border-bottom: 1px solid #E0E0E0;\n    width: 100%;\n    height: 60px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 0 14px;\n    z-index: 7;\n    background: #fff;\n}\n\n.title {\n    font-family: Inter, sans-serif;\n    font-size: 18px;\n    font-weight: 500;\n    line-height: 19.36px;\n    text-align: left;\n    color: #086A8C;\n}\n\n.burger {\n    border: 1px solid #E0E0E0;\n    border-radius: 5px;\n    height: 40px;\n    width: 40px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.navigate {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n    /*overflow: auto;*/\n    padding: 16px 0 0 0;\n}\n\n.logo {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `styles_header__Ekjwc`,
	"title": `styles_title__DC3mB`,
	"burger": `styles_burger__jRrqF`,
	"navigate": `styles_navigate__pb0QO`,
	"logo": `styles_logo__Ld28m`
};
export default ___CSS_LOADER_EXPORT___;
