import {CHAIN_NAMESPACES, WEB3AUTH_NETWORK, UX_MODE} from "@web3auth/base";
import {OpenloginAdapterOptions} from "@web3auth/openlogin-adapter";
import {EthereumPrivateKeyProvider} from "@web3auth/ethereum-provider";
import {SolanaPrivateKeyProvider} from "@web3auth/solana-provider";

const clientId = "BFR2s0rpVi2fKNoo8PNWOl3f6jlk1hUTClopJFQ9JkA0zwdZFsyZHsittYL1kr304lStTUD9LfcCY805-iEdxOU"; // get from https://dashboard.web3auth.io
// const clientId = "BHSJZUL8gWstMw4C25-1jlLZbcXydv6Sv_YHxG7I7eZH2B6pc8_cIviUINdHhALyUm3thHCXSTqVEP2RpdiBhDw"; // get from https://dashboard.web3auth.io

const chainConfig = {
    chainNamespace: "eip155",
    chainId: "0x89", // hex of 137, polygon mainnet
    rpcTarget: "https://rpc.ankr.com/polygon",
    // Avoid using public rpcTarget in production.
    // Use services like Infura, Quicknode etc
    displayName: "Polygon Mainnet",
    blockExplorerUrl: "https://polygonscan.com",
    ticker: "MATIC",
    tickerName: "MATIC",
    logo: "https://cryptologos.cc/logos/polygon-matic-logo.png",
};
const chainConfigSol = {
    chainId: "0x3",
    chainNamespace: CHAIN_NAMESPACES.SOLANA,
    rpcTarget: "https://api.testnet.solana.com",
    tickerName: "SOLANA",
    ticker: "SOL",
    decimals: 18,
    blockExplorerUrl: "https://explorer.solana.com",
    logo: "https://images.toruswallet.io/sol.svg",
};

const privateKeyProvider = new EthereumPrivateKeyProvider({config: {chainConfig}});
const privateKeyProviderSolana = new SolanaPrivateKeyProvider({
    config: {chainConfig: chainConfigSol},
});
export const web3AuthConfig = (id) => {
    return {
        clientId,
        privateKeyProvider: id === 1 ? privateKeyProvider : privateKeyProviderSolana,
        web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_DEVNET,
    }
};


export const openloginAdapterConfig: OpenloginAdapterOptions = {
    adapterSettings: {
        uxMode: UX_MODE.REDIRECT,
    },
};