import React from 'react';
import s from './styles.module.css'
import TitleCabinet from "../../../commom/titleCabinet";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const News = () => {
    return (
        <div className={s.news}>
            <div className={s.header}>
                <TitleCabinet title={'News'}/>

                <p className={s.link}>
                    All news
                    <ArrowForwardIosIcon sx={{width: 'auto', height: '14px'}}/>
                </p>
            </div>

            <div className={s.items}>
                <div className={s.item}>
                    <p className={s.item_title}>Added minimum terms for withdrawal of funds.</p>
                    <div className={s.item_bottom}>
                        <p className={s.item_bottom_left}>04/08/2022</p>
                        <p className={s.item_bottom_right}>...</p>
                    </div>
                </div>
                <div className={s.line}/>
                <div className={s.item}>
                    <p className={s.item_title}>Added minimum terms for withdrawal of funds.</p>
                    <div className={s.item_bottom}>
                        <p className={s.item_bottom_left}>04/08/2022</p>
                        <p className={s.item_bottom_right}>...</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default News;