import React, {useContext, useEffect, useState} from 'react';
import CustomModal from "../../../commom/modal";
import s from './styles.module.css'
import Input from "../../../commom/input";
import {Button} from "@mui/material";
import {AuthContext} from "../../../App";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {useGetProfileMutation, useRegistrationMutation} from "../../../store/profile.service";

const ModalReg = ({open, handleClose, handleCloseModal}) => {
    const {web3auth, setLoggedIn, setProfile} = useContext(AuthContext)

    const [getProfile] = useGetProfileMutation()
    const [registration] = useRegistrationMutation()

    const [value, setValue] = useState('')

    const handleRegistrationMutation = async () => {
        registration()
            .unwrap(value)
            .then((res) => {
                getProfile()
                    .unwrap()
                    .then((res) => {
                        setProfile(res)
                        setLoggedIn(true)
                        handleClose()
                    })
                    .catch((e) => {
                        console.log(e)
                    })
            })
            .catch((e) => {
                console.log(e)
                toast.error('Error')
            })
    }

    useEffect(() => {
        const getAppState = async () => {
            const user = await web3auth.getUserInfo();
            console.log(user)
            if (user?.appState) {
                setValue(user?.appState)
            }
        }
        getAppState()

    }, [])

    return (
        <CustomModal title={'Registration'} open={open} handleClose={handleCloseModal}>
            <div className={s.content}>
                <Input placeholder={'Enter inviter\'s ID'} value={value}
                       handleChange={(e) => setValue(e.target.value)}/>
                <Button variant="outlined" onClick={handleRegistrationMutation} fullWidth>
                    Confirm
                </Button>
            </div>
        </CustomModal>
    );
};

export default ModalReg;