import React from 'react';
import {Route, Routes} from "react-router-dom";
import Auth from "../page/auth";
import Main from "../page/cabinet";
import LkContainer from "../page/lkContainer";
import Lenders from "../page/lenders";
import Borrowers from "../page/borrowers";
import Affiliates from "../page/affiliates";
import Insurance from "../page/insurance";
import CreditPool from "../page/creaditPool";
import CreditCertificates from "../page/creaditCertificates";

const Routers = () => {
    return (
        <Routes>
            <Route path="/" element={<Auth/>}/>
            <Route path="/lk" element={<LkContainer/>}>
                <Route index element={<Main/>}/>
                <Route path={'token-shares'} element={<h1>in developing token-shares</h1>}/>
                <Route path={'structure'} element={<h1>in developing structure</h1>}/>
                <Route path={'partners'} element={<h1>in developing partners</h1>}/>
                <Route path={'history'} element={<h1>in developing history</h1>}/>
                <Route path={'news'} element={<h1>in developing news</h1>}/>
                <Route path={'lenders'} element={<Lenders/>}/>
                <Route path={'borrowers'} element={<Borrowers/>}/>
                <Route path={'affiliates'} element={<Affiliates/>}/>
                <Route path={'insurance'} element={<Insurance/>}/>
                <Route path={'credit-pool'} element={<CreditPool/>}/>
                <Route path={'credit-certificates'} element={<CreditCertificates/>}/>
            </Route>
        </Routes>
    );
};

export default Routers;
