import React from 'react';
import s from './styles.module.css'
import classNames from "classnames";

const CreditPool = () => {
    return (
        <div className={s.main}>
            <div className={s.text_box}>
                <p className={classNames(s.text, s.text_title)}>Credit Liquidity Pools</p>
                <p className={s.text}>
                    At the heart of Webtransfer’s decentralized credit network lie credit liquidity pools — smart
                    contracts that pool cryptocurrency funds provided by participants to finance P2P loans. Pools vary
                    in the type of collateral accepted and the associated level of risk:
                </p>
            </div>

            <div className={s.text_box}>
                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Secured pools: </b>
                  Loans are issued against the collateral of cryptocurrencies, stablecoins, NFTs, and other digital assets. The lower risk level and interest rates make these pools more attractive for creditors looking for more stable and secure investments.</span>
                </li>
                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Unsecured pools: </b>
                      Loans are issued based on the social credit scoring of participants without tangible collateral. The increased risk is accompanied by higher rates, which can offer higher returns for creditors willing to take on this risk.</span>
                </li>
                <li className={s.text_box_li}>
                    <span className={s.text}>
                        <b>Mixed pools: </b>
                        A combination of secured and unsecured loans with varying risk and return ratios. Mixed pools allow participants to diversify their investments within a single pool, balancing between risk and potential profit.</span>
                </li>

                <p className={s.text}>
                    Participants in the credit pool receive interest from the loans issued, proportional to their
                    contributed share of liquidity.
                </p>
            </div>

            <div className={classNames(s.text_box)}>
                <p className={classNames(s.text, s.text_title)}>Mechanism for Pool Yield Formation</p>
                <p className={s.text}>
                    The profitability of credit liquidity pools in <b>Webtransfer</b> is determined by the dynamics of
                    supply
                    and demand on the platform. Interest rates on loans are adjusted in real time, reflecting the
                    current balance between borrower needs and creditor willingness to provide funds. This creates a
                    natural regulation mechanism that promotes optimal resource allocation and provides competitive
                    conditions for all network participants.
                </p>
                <p className={s.text}>
                    Participants have the opportunity to choose pools with different levels of risk and potential
                    return, allowing them to individually tailor their investment portfolio according to their
                    preferences and financial goals. Thus, participants are not only able to manage their investments
                    but also actively influence their potential profit by choosing between more conservative or risky
                    lending strategies.
                </p>
                <p className={s.text}>
                    Additionally, the platform provides tools and analytics to assess the current market situation,
                    helping participants make informed decisions and optimize their return while managing risks.
                </p>
                <p className={s.text}>
                    These mechanisms ensure that the credit liquidity pools in <b>Webtransfer</b> not only serve as an
                    effective tool for financing loans but also provide participants with flexible opportunities for
                    investing and earning income.
                </p>
            </div>
        </div>
    );
};

export default CreditPool;