import {
    Connection,
    LAMPORTS_PER_SOL,
    PublicKey,
    SystemProgram,
    Transaction,
    TransactionMessage,
    VersionedTransaction,
} from "@solana/web3.js";
import {SolanaWallet} from "@web3auth/solana-provider";
import Web3 from "web3";

export default class SolanaRpc {
    provider;

    constructor(provider) {
        this.provider = provider;
    }

    getAccounts = async () => {
        try {
            const solanaWallet = new SolanaWallet(this.provider);
            const acc = await solanaWallet.requestAccounts();
            return acc;
        } catch (error) {
            return error;
        }
    };

    getBalance = async () => {
        try {
            const solanaWallet = new SolanaWallet(this.provider);
            const connectionConfig = await solanaWallet.request({
                method: "solana_provider_config",
                params: [],
            });
            const conn = new Connection(connectionConfig.rpcTarget);

            const accounts = await solanaWallet.requestAccounts();
            const balance = await conn.getBalance(new PublicKey(accounts[0]));
            return balance.toString();
        } catch (error) {
            return error;
        }
    };

    signMessage = async () => {
        try {
            const solanaWallet = new SolanaWallet(this.provider);
            const msg = Buffer.from("Test Signing Message ", "utf8");
            const res = await solanaWallet.signMessage(msg);
            return res.toString();
        } catch (error) {
            return error;
        }
    };

    async getChainId() {
        try {
            return 'solana';
        } catch (error) {
            return error;
        }
    }

    sendTransaction = async () => {
        try {
            const solanaWallet = new SolanaWallet(this.provider);

            const accounts = await solanaWallet.requestAccounts();

            const connectionConfig = await solanaWallet.request({
                method: "solana_provider_config",
                params: [],
            });
            const connection = new Connection(connectionConfig.rpcTarget);

            const block = await connection.getLatestBlockhash("finalized");

            const TransactionInstruction = SystemProgram.transfer({
                fromPubkey: new PublicKey(accounts[0]),
                toPubkey: new PublicKey(accounts[0]),
                lamports: 0.01 * LAMPORTS_PER_SOL,
            });

            const transaction = new Transaction({
                blockhash: block.blockhash,
                lastValidBlockHeight: block.lastValidBlockHeight,
                feePayer: new PublicKey(accounts[0]),
            }).add(TransactionInstruction);

            const {signature} = await solanaWallet.signAndSendTransaction(
                transaction
            );

            return signature;
        } catch (error) {
            return error;
        }
    };

    signTransaction = async () => {
        try {
            const solanaWallet = new SolanaWallet(this.provider);
            const connectionConfig = await solanaWallet.request({
                method: "solana_provider_config",
                params: [],
            });
            const conn = new Connection(connectionConfig.rpcTarget);

            const pubKey = await solanaWallet.requestAccounts();
            const {blockhash} = await conn.getRecentBlockhash("finalized");
            const TransactionInstruction = SystemProgram.transfer({
                fromPubkey: new PublicKey(pubKey[0]),
                toPubkey: new PublicKey(pubKey[0]),
                lamports: 0.01 * LAMPORTS_PER_SOL,
            });
            const transaction = new Transaction({
                recentBlockhash: blockhash,
                feePayer: new PublicKey(pubKey[0]),
            }).add(TransactionInstruction);
            const signedTx = await solanaWallet.signTransaction(transaction);
            return signedTx.signature?.toString() || "";
        } catch (error) {
            return error;
        }
    };

    sendVersionTransaction = async () => {
        try {
            const solanaWallet = new SolanaWallet(this.provider);
            const connectionConfig = await solanaWallet.request({method: "solana_provider_config", params: []});
            const conn = new Connection(connectionConfig.rpcTarget);

            const pubKey = await solanaWallet.requestAccounts();
            const {blockhash} = await conn.getLatestBlockhash("finalized");
            const TransactionInstruction = SystemProgram.transfer({
                fromPubkey: new PublicKey(pubKey[0]),
                toPubkey: new PublicKey(pubKey[0]),
                lamports: 0.01 * LAMPORTS_PER_SOL,
            });

            const transactionMessage = new TransactionMessage({
                recentBlockhash: blockhash,
                instructions: [TransactionInstruction],
                payerKey: new PublicKey(pubKey[0]),
            });
            const transaction = new VersionedTransaction(transactionMessage.compileToV0Message());
            const {signature} = await solanaWallet.signAndSendTransaction(transaction);
            return signature;
        } catch (error) {
            return error;
        }
    };

    signVersionedTransaction = async () => {
        try {
            const solanaWallet = new SolanaWallet(this.provider);
            const connectionConfig = await solanaWallet.request({method: "solana_provider_config", params: []});
            const conn = new Connection(connectionConfig.rpcTarget);

            const pubKey = await solanaWallet.requestAccounts();
            const {blockhash} = await conn.getRecentBlockhash("finalized");
            const TransactionInstruction = SystemProgram.transfer({
                fromPubkey: new PublicKey(pubKey[0]),
                toPubkey: new PublicKey(pubKey[0]),
                lamports: 0.01 * LAMPORTS_PER_SOL,
            });

            const transactionMessage = new TransactionMessage({
                recentBlockhash: blockhash,
                instructions: [TransactionInstruction],
                payerKey: new PublicKey(pubKey[0]),
            });
            const transaction = new VersionedTransaction(transactionMessage.compileToV0Message());

            const signedTx = await solanaWallet.signTransaction <VersionedTransaction>(transaction);
            return signedTx;
        } catch (error) {
            throw error;
        }
    };
    signAllTransaction = async () => {
        try {
            const solanaWallet = new SolanaWallet(this.provider);
            const connectionConfig = await solanaWallet.request({method: "solana_provider_config", params: []});
            const conn = new Connection(connectionConfig.rpcTarget);

            const pubKey = await solanaWallet.requestAccounts();
            const {blockhash} = await conn.getRecentBlockhash("finalized");

            const TransactionInstruction = SystemProgram.transfer({
                fromPubkey: new PublicKey(pubKey[0]),
                toPubkey: new PublicKey(pubKey[0]),
                lamports: 0.01 * LAMPORTS_PER_SOL,
            });
            const TransactionInstruction1 = SystemProgram.transfer({
                fromPubkey: new PublicKey(pubKey[0]),
                toPubkey: new PublicKey(pubKey[0]),
                lamports: 0.02 * LAMPORTS_PER_SOL,
            });
            const TransactionInstruction2 = SystemProgram.transfer({
                fromPubkey: new PublicKey(pubKey[0]),
                toPubkey: new PublicKey(pubKey[0]),
                lamports: 0.03 * LAMPORTS_PER_SOL,
            });
            const transaction = new Transaction({
                recentBlockhash: blockhash,
                feePayer: new PublicKey(pubKey[0])
            }).add(TransactionInstruction);
            const transaction1 = new Transaction({
                recentBlockhash: blockhash,
                feePayer: new PublicKey(pubKey[0])
            }).add(TransactionInstruction1);
            const transaction2 = new Transaction({
                recentBlockhash: blockhash,
                feePayer: new PublicKey(pubKey[0])
            }).add(TransactionInstruction2);

            const signedTx = await solanaWallet.signAllTransactions([transaction, transaction1, transaction2]);
            return signedTx;
        } catch (error) {
            throw error;
            // return error as string;
        }
    };


    signAllVersionedTransaction = async () => {
        try {
            const solanaWallet = new SolanaWallet(this.provider);
            const connectionConfig = await solanaWallet.request({method: "solana_provider_config", params: []});
            const conn = new Connection(connectionConfig.rpcTarget);

            const pubKey = await solanaWallet.requestAccounts();
            const {blockhash} = await conn.getRecentBlockhash("finalized");


            const transactionInstruction = SystemProgram.transfer({
                fromPubkey: new PublicKey(pubKey[0]),
                toPubkey: new PublicKey(pubKey[0]),
                lamports: 0.01 * LAMPORTS_PER_SOL,
            });
            const transactionInstruction1 = SystemProgram.transfer({
                fromPubkey: new PublicKey(pubKey[0]),
                toPubkey: new PublicKey(pubKey[0]),
                lamports: 0.02 * LAMPORTS_PER_SOL,
            });
            const transactionInstruction2 = SystemProgram.transfer({
                fromPubkey: new PublicKey(pubKey[0]),
                toPubkey: new PublicKey(pubKey[0]),
                lamports: 0.03 * LAMPORTS_PER_SOL,
            });
            const transactionMessage = new TransactionMessage({
                recentBlockhash: blockhash,
                payerKey: new PublicKey(pubKey[0]),
                instructions: [transactionInstruction]
            })
            const transactionMessage1 = new TransactionMessage({
                recentBlockhash: blockhash,
                payerKey: new PublicKey(pubKey[0]),
                instructions: [transactionInstruction1]
            })
            const transactionMessage2 = new TransactionMessage({
                recentBlockhash: blockhash,
                payerKey: new PublicKey(pubKey[0]),
                instructions: [transactionInstruction2]
            })
            const transaction = new VersionedTransaction(transactionMessage.compileToV0Message());
            const transaction1 = new VersionedTransaction(transactionMessage1.compileToV0Message());
            const transaction2 = new VersionedTransaction(transactionMessage2.compileToV0Message());
            const signedTx = await solanaWallet.signAllTransactions([transaction, transaction1, transaction2]);
            console.log(signedTx);
            return signedTx;
        } catch (error) {
            throw error;
            // return error as string;
        }
    };

    // getPrivateKey = async () => {
    //     const privateKey = await this.provider.request({
    //         method: "solanaPrivateKey",
    //     });
    //
    //     return privateKey;
    // };
}
