// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_box__\\+onY9 {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    /*border: 1px solid red;*/
    height: calc(100%);
    padding: 0 5px 0 5px;
}

.styles_box__\\+onY9::-webkit-scrollbar {
    width: 0.2em;
    border-radius: 10px;
}

.styles_box__\\+onY9::-webkit-scrollbar-track {
    /*color: red;*/
    background: #cecece;
    border-radius: 10px;
}

.styles_box__\\+onY9::-webkit-scrollbar-thumb {
    background-color: #086a8c;
    border-radius: 10px;
}

`, "",{"version":3,"sources":["webpack://./src/component/twitterContent/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;AACvB","sourcesContent":[".box {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    overflow-x: hidden;\n    /*border: 1px solid red;*/\n    height: calc(100%);\n    padding: 0 5px 0 5px;\n}\n\n.box::-webkit-scrollbar {\n    width: 0.2em;\n    border-radius: 10px;\n}\n\n.box::-webkit-scrollbar-track {\n    /*color: red;*/\n    background: #cecece;\n    border-radius: 10px;\n}\n\n.box::-webkit-scrollbar-thumb {\n    background-color: #086a8c;\n    border-radius: 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `styles_box__+onY9`
};
export default ___CSS_LOADER_EXPORT___;
